import '@reach/accordion/styles.css';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import FeatureWrapper, { Content, FeatureContent, Ipad } from './feature.style';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../../home/components/UI/Container';
import GatsbyImage from '../../../home/components/GatsbyImage';
import { Icon } from 'react-icons-kit';
import React from 'react';
import SectionHeading from '../../../home/components/SectionHeading';
import Text from '../../../home/components/Text';
import { thinRight } from 'react-icons-kit/entypo/thinRight';

const ContactSection = () => {
  const data = useStaticQuery(graphql`
    query {
      iPad: file(relativePath: { eq: "ipad.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1018, placeholder: BLURRED)
        }
      }
      contactJson {
        accordions {
          id
          expanded
          title
          desc
        }
      }
    }
  `);
  return (
    <FeatureWrapper id="features">
      <Ipad>
        <GatsbyImage
          src={
            (data.iPad !== null) | undefined
              ? data.iPad.childImageSharp.gatsbyImageData
              : {}
          }
          alt="iPad"
        />
      </Ipad>
      <Container>
        <FeatureContent>
          <SectionHeading
            mb="20px"
            slogan="Enterprise first"
            title="World-class Engineers"
            textAlign="left"
          />
          <Content>
            <Text
              className="caption"
              content="Build an incredible workplace and grow your business with Algo Inc."
            />
            <Accordion>
              {data.contactJson.accordions.map((item) => {
                return (
                  <AccordionItem key={item.id}>
                    <AccordionButton>
                      {item.title} <Icon icon={thinRight} />
                    </AccordionButton>
                    <AccordionPanel>{item.desc}</AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Content>
        </FeatureContent>
      </Container>
    </FeatureWrapper>
  );
};

export default ContactSection;
