import Contact from '../containers/contact';
import Layout from '../containers/layout';
import React from 'react';
import SEO from '../components/seo';

type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="Contact Algo Inc today."
      />

      <Contact />
    </Layout>
  );
};

export default ContactPage;
