import * as React from 'react';
import * as Yup from 'yup';

import {
	ContactFromWrapper,
	ContactPageTitle,
	ContactWrapper,
	InputGroup,
} from './style';
import { Form, Formik, FormikProps } from 'formik';

import Box from '../../home/components/Box';
import Button from '../../components/button/button';
import ContactSection from './ContactSection';
import Input from '../../components/input/input';
import Recaptcha from 'react-recaptcha';
import { isEmpty } from 'lodash';

interface MyFormValues {
	firstName: string;
	email: string;
	message: string;
	captcha: string;
}

const SignupSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	message: Yup.string().required('Required')
});

const Contact: React.SFC<{}> = () => {

	const [captcha, setCaptcha] = React.useState<string>("");
	// specifying your onload callback function
	const callback = () => {
		console.log('Done!!!!');
	};

	// specifying verify callback function
	const verifyCallback = (response: any) => {
		console.log(response);
		setCaptcha(response);
	};

	function encode(data: any) {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&")
	}

	return (
		<>
			<ContactPageTitle>
				<h1>Contact</h1>
				<p>
					Let it be an app, an idea, or a business, plan shoot us feedback below, we take time to answer all requests.
				</p>
			</ContactPageTitle>
			<ContactSection />
			<Formik
				initialValues={{ firstName: '', email: '', message: '', captcha: '' }}
				onSubmit={(values: MyFormValues, actions: any) => {

					if (isEmpty(captcha)) {
						actions.setFieldError("message", "please solve captcha");
						return actions.setSubmitting(false);
					};

					fetch("/", {
						method: "POST",
						headers: { "Content-Type": "application/x-www-form-urlencoded" },
						body: encode({
							"form-name": "contact",
							...values
						})
					}).then(() => {
						console.log('form values', { values, actions });
						actions.setSubmitting(false);

						window.location.href = "/thank-you";

					}).catch(error => alert(error))

				}}
				validationSchema={SignupSchema}
				render={({
					handleChange,
					values,
					errors,
					handleBlur,
					touched,
					isSubmitting,
				}: FormikProps<MyFormValues>) => (
					<>
						<Form name='contact'>
							<ContactWrapper>

								<ContactFromWrapper>
									<InputGroup>
										<Input
											type='text'
											name='firstName'
											value={`${values.firstName}`}
											onChange={handleChange}
											onBlur={handleBlur}
											label='Name'
											notification={`${errors.firstName && touched.firstName
												? errors.firstName
												: ''
												}`}
										/>
										<Input
											type='email'
											name='email'
											value={`${values.email}`}
											onChange={handleChange}
											onBlur={handleBlur}
											label='Email'
											notification={`${errors.email && touched.email ? errors.email : ''
												}`}
										/>
									</InputGroup>
									<Input
										type='textarea'
										name='message'
										value={`${values.message}`}
										onChange={handleChange}
										onBlur={handleBlur}
										label='Message'
										notification={
											errors.message && touched.message ? errors.message : ''
										}
									/>

									<div style={{
										width: "100%", display: "flex",
										alignItems: "center",
										justifyContent: "center",
										paddingTop: "20px"
									}}>
										<Recaptcha
											sitekey="6LdQOhweAAAAABYl-j-ZtoX-UFMhgwmc1EP403ll"
											render="explicit"
											verifyCallback={verifyCallback}
											onloadCallback={callback}
										/>
									</div>


									<Button
										title='Submit'
										type='submit'
										isLoading={isSubmitting ? true : false}
										loader='Submitting..'
									/>
								</ContactFromWrapper>
							</ContactWrapper>
						</Form>
					</>
				)}
			/>
		</>
	);
};

export default Contact;
